<template>
  <div class="page">
    <div class="main">
      <div class="title">添加导师</div>
      <el-form ref="form" :model="formData" label-width="180px" :inline="true" style="margin-top: 40px">
        <el-form-item label="导师姓名">
          <el-input v-model="formData.name" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="手机号">
          <el-input v-model="formData.mobile" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="登陆密码">
          <el-input v-model="formData.pwd" size="mini"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="导师简介">
          <el-input v-model="formData.intro" type="textarea"></el-input>
        </el-form-item>
        <br />
        <el-form-item label="收费标准">
          <el-input v-model="formData.fee" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="formData.sex" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="formData.address" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="职称">
          <el-input v-model="formData.academic_title" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-input v-model="formData.status" size="mini"></el-input>
        </el-form-item>
        <br />
      </el-form>

      <div class="button">
        <el-button type="primary" size="mini" @click="add">确认添加</el-button>
      </div>
    </div>
  </div>
</template>

<script>
//time: 2021/1/21
export default {
  name: "Expert",
  data() {
    return {
      formData: {
        name: "",
        logo: "",
        mobile: "",
        pwd: "",
        fee: "",
        sex: "",
        address: "",
        academic_title: "",
        intro: "",
        desc: "",
        status: "",
      },
    };
  },
  created() {},
  methods: {
    add() {
      const ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
      const data = {
        parent_id: ExpertInfo.id,
        mobile: this.formData.mobile,
        name: this.formData.name,
        logo: this.formData.logo,
        pwd: this.formData.pwd,
        fee: this.formData.fee,
        sex: this.formData.sex,
        address: this.formData.address,
        academic_title: this.formData.academic_title,
        intro: this.formData.intro,
        desc: this.formData.desc,
        status: this.formData.status,
      };
      this.$fecth.post("expert/add", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, msg);
          setTimeout(() => {
            this.$router.push("/expert_list");
          }, 1000);
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  position: relative;
  left: 500px;
  top: 20px;
  margin-bottom: 100px;
}
</style>